import { Component, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { Location } from '@angular/common';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { NbDialogRef, NbDialogService } from '@nebular/theme';


import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil, filter, catchError, tap, flatMap, mergeMap, switchMap, retry, take } from 'rxjs/operators';
import {Observable, throwError, Subject, from, forkJoin} from 'rxjs';

import { Router, NavigationEnd } from '@angular/router';

import { AuthService } from '../../../auth.service';
import { AccountsService } from '../../../services/accounts.service';

import { analyticsMapping } from './analytics-mapping';
import { AnalyticsService } from '../../../services/analytics.service';
import { WebsiteService } from '../../../services/website.service';
import { PagesService } from '../../../services/pages.service';
import { HelpCenterService } from '../../../pages/help-center/help.service';


import { CreateUserDialogComponent } from '../../../components/create-user-dialog/create-user-dialog.component';
import { AreYouSureDialogComponent } from '../../../components/are-you-sure-dialog/are-you-sure-dialog.component';
import { MediaLibraryDialogComponent } from '../../../components/media-library-dialog/media-library-dialog.component';
import { MobileMessageDialog } from '../../../components/mobile-message-dialog/mobile-message-dialog.component';
import { AdminCustomizationDialogComponent } from '../../../components/admin-customization-dialog/admin-customization-dialog.component';
import { AccountCustomizationDialogComponent } from '../../../components/account-customization-dialog/account-customization-dialog.component';
import { AccountComponent } from '../../../pages/account/account.component';
import { templatesUseCase } from '../../../components/wizard/usecases';
import { UsersService } from '../../../services/usersV2.service';
import { ToasterService } from '../../../services/toaster.service';
import { SenderDataService } from '../../../pages/account/account.service';
import { SubscriptionService } from '../../../pages/profile/subscription/subscription.service';
import { CheckPaymentStatusDialogComponent } from '../../../components/check-payment-status-dialog/check-payment-status-dialog.component';
import { GettingStartDialogComponent } from '../../../components/getting-start-dialog/getting-start-dialog.component';

import { environment } from '../../../../environments/environment';

declare const Paddle;
declare const hj: any;

import * as moment from 'moment';
import { DiscardWizardDialog } from '../../../components/wizard/discard-wizard-dialog/discard-wizard-dialog.component';

const { detect } = require('detect-browser');
const browser = detect();

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  clickSubscription;
  userPictureOnly: boolean = false;
  user$ = this.auth.currentUser$;
  userInfo: any;
  admin = false;
  administrator = false;
  adminCore = false;
  userType = 'user';
  orgId;
  orgInfo;
  viewId = null;
  analyticsLink;
  accountsLoading = true;
  daysForTrialEnd = 0;
  daysForCancelAt = 0;
  showTrialEndBar = false;
  showTrialEndBarClosed = false;
  trialEndedDialogOpen = false;
  showBrowserSupportMessage = false;
  mobileLoader = false;
  hideMenu = false;
  isOffline = false;
  showRequestTeamMessage = false;
  sendingRequest = false;
  requestedOrg: any = {}
  accountCustomization$ = this.accountsService.accountCustomization$;
  isOrgAdmin: boolean;
  isEditorV4User = false;
  totalLiveStoriesCount: number = 1;
  orgs: any[] = [
    // {
    //   value: '9d024a9c40768a42',
    //   title: 'DoTell',
    // },
    // {
    //   value: '2001db1539689c6d',
    //   title: 'Sales demo',
    // },
    // {
    //   value: '3883d336d323cea7',
    //   title: 'RFKeeper',
    // }
  ]
  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu: any = [
    {
      title: "Help",
      data: {
        helpCenter: true
      },
      icon: 'question-mark-circle-outline'
    },
    {
      title: "My profile",
      data: {
        account: true,
      },
      icon: 'person-outline'
    },
    {
      title: "Log Out",
      data: {
        link: "/pages/profile/logout",
      },
      icon: 'log-out-outline'
      // pathMatch: "full"
    }
  ];
  customize = {
    title: "Account customization",
    data: {
      customize: true,
    },
    icon: 'color-palette-outline'
  };
  mediaLibrary = {
    title: "Media library",
    // link: "/pages/profile/info",
    // pathMatch: "full"
    data: {
      media: true,
    },
    icon: 'image-outline'
  }
  refreshSession = {
    title: "Refresh session",
    data: {
      refreshSession: true,
    },
    icon: 'sync-outline'
  }
  fastVersionVariables = {
    title: "Variables",
    data: {
      link: '/pages/editor/variables',
    },
    icon: 'code-outline'

  }
  settings = {
      title: "Settings",
      data: {
        link: "/pages/profile/users",
      },
      icon: 'people-outline'
  }
  adminCustomization = {
    title: 'Admin customization',
    data: {
      adminCustomization: true
    },
    icon: 'settings-2-outline'
  }
  updateEditorV4 = {
    title: 'Update Editor',
    data: {
      updateEditorV4: true,
    },
    icon: 'person-outline'
  }


  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private users: UsersService,
              private layoutService: LayoutService,
              private analyticsService: AnalyticsService,
              private websiteService: WebsiteService,
              private pagesService: PagesService,
              private breakpointService: NbMediaBreakpointsService,
              public router: Router,
              private dialogService: NbDialogService,
              private ref: ChangeDetectorRef,
              private accountsService: AccountsService,
              private senderDataService: SenderDataService,
              private toasterService: ToasterService,
              private location: Location,
              private subscriptionService: SubscriptionService,
              private helpCenterService: HelpCenterService,
              public auth: AuthService) {
  }

  ngOnInit() {
    if(window.innerWidth < 992) {
      if(window.location.pathname !== '/pages/analytics' && this.router.url.indexOf('headerDisabled=true') <= -1) this.openMobileDialog();
      this.hideMenu = true;
    }
    this.setupPaddle();
    this.currentTheme = this.themeService.currentTheme;
    this.initClickListener();
    this.auth.getOrgId$().subscribe(orgId => this.orgId = orgId);
    this.auth.getAdminCore$().subscribe(admin => {
      this.adminCore = (admin === true)
      if(this.adminCore) {
        if(typeof(hj) === 'function') hj('event', 'Admin core');
        this.userMenu.push(this.refreshSession);
        this.userMenu.unshift(this.mediaLibrary);
        this.userMenu.push(this.adminCustomization);
        this.userMenu.push(this.updateEditorV4);
      }
      else if(typeof(hj) === 'function') hj('event', 'No admin core');
    })
    forkJoin([
      this.accountsService.info(),
      this.auth.isAdministrator$()
    ]).subscribe(([ info, isAdmin ]) => {
      if (info.plan !== 'trial' && isAdmin) this.userMenu.splice(1,0,this.fastVersionVariables)
      this.showRequestOrgDialog(info);
      if(this.accountsService.isB2B && isAdmin || this.adminCore) this.userMenu.splice(2,0,this.customize);
    })
    this.userType = this.auth.userType;
    this.accountsService.info().subscribe(orgInfo => {
      if(!orgInfo) return;
      this.isEditorV4User = orgInfo.parameters && orgInfo.parameters.isEditorV4;
      if (!this.adminCore && this.isWrongEnvironment()) return this.redirectToCorrectEnvironment()
      else if (this.adminCore && this.isWrongEnvironment()) this.wrongEnvAdminMessage();
      this.orgInfo = orgInfo;
      if (this.orgInfo.customization) this.accountsService.updateAccountCustomization(this.orgInfo.customization);
      if (![ 'user', 'orgAdmin', 'creatorLite' ].includes(this.userType)) {
        this.userMenu.splice(1,0,this.settings);
      }
      this.checkFreeTrialEnd(orgInfo);
      this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          this.checkFreeTrialEnd(orgInfo);
        }
      });
      if(typeof(hj) === 'function') {
        orgInfo.isSelfServ ? hj('event', 'Self Serve') : hj('event', 'B2B');
      }
      this.loadHubspotChat();
    });
    this.auth.getAnalyticsLink$().subscribe(analyticsLink => {
      this.analyticsLink = analyticsLink ? analyticsLink : analyticsMapping[this.orgId];
    });
    this.auth.isAdmin$().subscribe(admin => this.admin = (admin === true))
    this.auth.isAdministrator$().subscribe(administrator => this.administrator = (administrator === true))

    this.auth.getUser$()
      .subscribe(user => {
        this.auth.updateCurrentUser(user);
        if(typeof(hj) === 'function') hj('event', user.sub);
      });

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.showBrowserSupportMessage = browser.name === 'firefox' || browser.name === 'safari';

    window.addEventListener("offline", (event) => {
      this.isOffline = true;
    });

    window.addEventListener("online", (event) => {
      this.isOffline = false;
    });
    this.getTotalLiveStoriesCount();
  }

  ngAfterViewInit() {
    if (this.router.url.indexOf('headerDisabled=true') <= -1) return;
    const body = document.getElementsByTagName('body');
    if (body.length) body[0].classList.add('header-disabled');
  }

  isWrongEnvironment() {
    return (this.isEditorEnvironment() && !this.isEditorV4User) || (!this.isEditorEnvironment() && this.isEditorV4User);
  }

  isEditorEnvironment() {
    return window.location.hostname.indexOf('editor-staging') > -1 || window.location.hostname.indexOf('editor.storydoc') > -1;
  }

  redirectToCorrectEnvironment() {
    let redirectLink = this.isEditorV4User ? environment.editorV4Url : environment.editorUrl;
    this.analyticsService.track('success redirect to right editor environment', {
      'redirect-link': redirectLink
    })
    return window.location.href = redirectLink;
  }

  showRequestOrgDialog(info) {
    this.users.me()
    .pipe(
      mergeMap((user: any) => {
        this.userInfo = user;
        this.updateSenderData(info, user);
        if (info !== 'trial') return from([[]]);
        return this.accountsService.getDomainOrg().pipe(
          catchError((err: any): Observable<any> => {
            this.analyticsService.track('Failed to get requested domain');
            return from([[]]);
          })
        )
      }),
      retry(1),
      catchError((err: any): Observable<any> => {
        // if(err.status === 500) this.auth.login();
        this.senderDataService.updatePersonalInfoData(undefined)
        return throwError(err);
      })
    )
    .subscribe(org => {
      if(!org.length) return
      this.requestedOrg = org[0];
      if(this.userInfo['requestedOrgId']) this.showRequestTeamMessage = true;
    })
  }

  updateSenderData(info, user) {
    const customization = info.customization;
    const { profile, email, firstName } = user;
    const { picture = '', calendar = '' } = { ...profile };
    const sender = { name: firstName, email, picture, calendar };
    const org = { title: info.title, website: info.website || '', logo: customization && customization.logo ? customization.logo : '' }
    this.senderDataService.updatePersonalInfoData({org, sender})
  }
  openMobileDialog() {
    let totalStories = 0;
    this.mobileLoader = true;
    this.pagesService.count()
    .pipe(
      catchError((err: any) : Observable<any> => {
        this.mobileLoader = false;
        return throwError(err);
      })
    )
    .subscribe(res => {
      totalStories = +res.count || 0;
      if(totalStories > 0) {
        this.mobileLoader = false;
        return this.router.navigateByUrl( '/pages/analytics');
      }
      this.mobileLoader = false;
      this.dialogService.open(MobileMessageDialog)
      this.analyticsService.track('view wizard on mobile device notification', {
        'screen': 'stories library'
      });
    });
  }

  setupPaddle() {
    if(environment.env !== 'production') Paddle.Environment.set('sandbox');
    Paddle.Setup({
      vendor: environment.paddle.vendorId,
      eventCallback: (data) => {
        if (data.event === "Checkout.PaymentMethodSelected") {
          this.analyticsService.track('click change payment method', {
            'screen': 'paddle-checkout-dialog',
            'payment method': data.eventData.payment_method
          });
        } else if(data.event === "Checkout.Loaded") {
          this.analyticsService.track('success payment dialog loaded', {
            'screen': 'paddle-checkout-dialog'
          });
        } else if (data.event === "Checkout.Error") {
          this.analyticsService.track('failed payment transaction', {
            'screen': 'paddle-checkout-dialog',
            'failure message': data.eventData.error
          });
        } else if(data.event === "Checkout.PaymentComplete") {
          this.analyticsService.track('success payment transaction', {
            'screen': 'paddle-checkout-dialog'
          });
        }
      }

    });

  }

  initClickListener() {
    this.clickSubscription = this.menuService.onItemClick()
    .pipe(
      filter((obj: any) => obj.tag === 'userMenu')
    )
    .subscribe((item: any) => {
      this.analyticsService.track('click user menu', {'item name': item.item.title});
      if(item.item.data.link){
        if(item.item.data.target) return window.open(item.item.data.link, item.item.data.target);
        this.router.navigateByUrl(item.item.data.link);
      } else if(item.item.data.media) {
        this.dialogService.open(MediaLibraryDialogComponent, {
          closeOnBackdropClick: false,
          closeOnEsc: false
        });
      } else if(item.item.data.refreshSession) {
        this.auth.login();
      } else if (item.item.data.adminCustomization) {
        this.openAdminCustomizationDialog();
      } else if(item.item.data.customize) {
        this.dialogService.open(AccountCustomizationDialogComponent, {
          closeOnBackdropClick: false,
          closeOnEsc: false,
          context: { }
        }).onClose.subscribe(orgInfo => {
          if (!orgInfo) return;
          this.orgInfo = orgInfo;
        });
      } else if (item.item.data.account) {
        this.dialogService.open(AccountComponent, {context: { }})
      }
      else if (item.item.data.updateEditorV4) {
        this.updateToV4Confirmation();
      } else if (item.ite.data.helpCenter) {
        this.openHelpPage();
      }
    });
  }

  updateEditorV4User() {
    this.accountsService.setEditorV4User(!this.isEditorV4User).subscribe(res => {
      this.isEditorV4User = res.parameters.isEditorV4;
      window.location.reload();
    });
  }

  checkFreeTrialEnd(orgInfo) {
    if(this.ignoreSubscribeNow) return;
    if(this.trialEndedDialogOpen || this.router.url === '/pages/profile/plans') return;
    this.orgInfo = orgInfo;
    if(orgInfo.analyticsViewId) this.viewId = orgInfo.analyticsViewId;
    if(orgInfo.plan === 'trial' && orgInfo.trialEnd) {
      var now = moment(new Date()); //todays date
      var end = moment(orgInfo.trialEnd); // another date
      var duration = moment.duration(end.diff(now));
      var days = duration.asDays();
      if(days <= 0) {
        this.trialEndedDialogOpen = true;
        this.analyticsService.sendHubspotProperty({ trial_status: 'expired' });
        this.analyticsService.sendHubspotInternalEvent('trial status expired');
        this.dialogService.open(AreYouSureDialogComponent, {
          ...this.dialogClosingParams,
          context: {
            title: 'Subscribe',
            body: orgInfo.subscriptionPlanId ? 'Your subscription has expired. Subscribe to keep using storydoc.' : 'Your free trial has ended. Subscribe to keep using storydoc.',
            buttons: [{
              title: 'Subscribe',
              value: true,
              status: 'primary'
            }]
          }
        }).onClose.subscribe(val => {
          if(!val) return;
          if(this.ignoreSubscribeNow) return;
          this.trialEndedDialogOpen = false;
          this.subscribeNow();
        })
        this.showTrialEndBar = false;
        this.daysForTrialEnd = 0;
        if(!this.orgInfo.postTrialVisit) this.postTrialVisitDate();
      } else {
        this.daysForTrialEnd = parseInt(days.toString()) + 1;
        this.showTrialEndBar = false;
      }
    } else if(this.orgInfo.plan === 'paid' && this.orgInfo.cancelAt) {
      var now = moment(new Date()); //todays date
      var end = moment(this.orgInfo.cancelAt); // another date
      var duration = moment.duration(end.diff(now));
      var days = duration.asDays();
      this.daysForCancelAt = parseInt(days.toString()) + 1;
      var isExpired = end.diff(now) < 0
      if(!isExpired || this.router.url.indexOf('/help') > -1) return;
      const selectedOrgPlan = environment.paddle.plans.find(p => p.id === this.orgInfo.subscriptionPlanId);
      const canActiveSubscription = selectedOrgPlan && [ 'Starter', 'Pro' ].includes(selectedOrgPlan.title) && this.administrator;
      this.areYouSureSubscriptionDialog(canActiveSubscription, selectedOrgPlan);
    }
  }



  postTrialVisitDate() {
    const isCreatedAfterApril26 = (new Date(this.orgInfo.createdAt).getTime() > new Date('2022-04-26T00:00:00.000+00:00').getTime());
    if (!isCreatedAfterApril26) return;
    this.accountsService.postTrialVisitDate()
    .pipe(
      catchError((err: any) : Observable<any> => {
        return throwError(err);
      })
    )
    .subscribe(info => {
      this.orgInfo = info;
      this.accountsService.updateOrgInfo(this.orgInfo);
      const date = moment(new Date()).utc().startOf('day');
      this.analyticsService.sendHubspotInternalEvent('post trial visit', { date: moment().format('DD/MM/YYYY hh:mm') });
      this.analyticsService.sendHubspotProperty({ post_trial_visit_date: date.valueOf() });
    })
  }

  areYouSureSubscriptionDialog(canActiveSubscription, selectedOrgPlan) {
      this.dialogService.open(AreYouSureDialogComponent, {
        ...this.dialogClosingParams,
        context: {
          title: 'Activate account',
          body: canActiveSubscription
            ? 'Your subscription has expired. Please re-activate your account or contact us for more details.'
            : 'Your subscription has expired. Please contact us to Re-activate your account',
          buttons: [{
            title: canActiveSubscription ? 'Activate account' : 'Contact us',
            value: true,
            status: 'primary'
          }]
        }
      }).onClose.subscribe(val => {
        if(!val) return;
        if (canActiveSubscription) return this.openCheckout(selectedOrgPlan);
        else this.subscriptionExpiredFoundContact();
      })
  }

  subscriptionExpiredFoundContact() {
    const data = {
      topic: 'Billing and plans',
      subject: 'Re-activate my account'
    }
    this.helpCenterService.setFormFieldsData(data)
    this.router.navigateByUrl('/pages/help');
  }

  openCheckout(selectedPlan) {
    if(!selectedPlan) console.error('plan not found');
    const subscriptionAnalyticsObject = {
      'subscription title': selectedPlan.title,
      'subscription period': selectedPlan.period,
      'subscription price': selectedPlan.price
    };
    this.analyticsService.track('click select subscription', subscriptionAnalyticsObject);
    const paddleOptions = {
      product: selectedPlan.id,
      email: this.userInfo.email,
      allowQuantity: false,
      disableLogout: (environment.env === 'production') ? true : false,
      closeCallback: (data) => {
        if(data.checkout.completed) {
          this.analyticsService.track('payment dialog success', subscriptionAnalyticsObject);
          this.dialogService.open(CheckPaymentStatusDialogComponent, {
            closeOnBackdropClick: false,
            closeOnEsc: false,
            context: {
              subscriptionAnalyticsObject,
              currentSubscriptionId: this.orgInfo.subscriptionId
            }
          });
          this.subscriptionService.deleteCookie('sd-paddle-coupon', "/", "storydoc.com");
        } else {
          this.analyticsService.track('payment dialog closed', subscriptionAnalyticsObject);
          this.areYouSureSubscriptionDialog(true, selectedPlan);
        }
      }
    }
    const coupon = this.getCouponCode();
    if (coupon) paddleOptions[ 'coupon' ] = coupon;
    Paddle.Checkout.open(paddleOptions);
  }

  getCouponCode() {
    const code = this.subscriptionService.getCookie('sd-paddle-coupon');
    return code ? code : null;
  }

  checkCanceledSubscription() {
    if(!this.orgInfo || !this.orgInfo.cancelAt) return

  }

  get dialogClosingParams() {
    return this.adminCore ? {} : {
      closeOnBackdropClick: false,
      closeOnEsc: false,
    }
  }

  createUser() {
    this.dialogService.open(CreateUserDialogComponent, {
      context: {
        orgInfo: this.orgInfo
      }
    })

  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if(this.clickSubscription) this.clickSubscription.unsubscribe();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.router.navigateByUrl('/pages/editor');
    // this.menuService.navigateHome();
    return false;
  }

  scheduleMeeting() {
    window.open('https://calendly.com/yaacovg/onboarding', '_blank');
  }

  openAnalytics() {
    this.analyticsService.track('click analytics');
    window.open(this.analyticsLink, '_blank');
  }

  changeOrgId(value) { // src/app/auth.service.ts
    this.auth.setOrgId(value);
    this.updateURLState();
    window.location.reload();
  }

  updateURLState() {
    if(!this.location.path().includes('folder')) return
    this.location.replaceState('/pages/editor');
  }

  changeUserType(value) { // src/app/auth.service.ts
    this.auth.userType = value;
    window.location.reload();
  }

  clickFloatingSubscribeNow() {
    this.analyticsService.track('click subscribe now floating cta');
    this.subscribeNow();
  }

  clickHeaderSubscribeNow() {
    this.analyticsService.track('click subscribe now header');
    this.subscribeNow();
  }

  get ignoreSubscribeNow() {
    return this.adminCore && this.admin;
  }

  subscribeNow() {
    this.showTrialEndBar = false;
    this.router.navigateByUrl('/pages/profile/plans');
  }

  openHelp() {
    this.analyticsService.track('click help link');
    window.open('https://www.storydoc.com/how-to-videos', '_blank');
  }

  openAdminCustomizationDialog() {
    this.dialogService.open(AdminCustomizationDialogComponent, {
      context: {}
    })
  }

  openSubscriptionDetailsDialog() {
    if(!this.adminCore) return;
    const plan = this.plan;
    this.dialogService.open(AreYouSureDialogComponent, {
      context: {
        title: 'Your subscription details',
        body: `
          Plan: ${plan.title}<br />
          Period: ${plan.period}<br />
          Price: ${this.orgInfo.subscriptionPrice}<br />
          Plan id: ${this.orgInfo.subscriptionPlanId}<br />
          Subscription id: ${this.orgInfo.subscriptionId}<br />
          Last Payment: ${new Date(this.orgInfo.lastSuccessfulPayment).toLocaleDateString()}<br />
          Next Payment: ${new Date(this.orgInfo.nextPayment).toLocaleDateString()}<br />
        `,
        buttons: [{
          title: 'Dismiss',
          value: true,
          status: 'basic'
        }]
      }
    })
  }

  loadHubspotChat() {
    const chat = document.getElementById('hubspot-messages-iframe-container');
    if (chat && (!this.accountsService.isB2B ||  this.accountsService.isAppSumo)) return chat.classList.add('hidden');
  }

  requestOrgAccess() {
    this.analyticsService.track('click send request org access', {
      'user email': this.userInfo.email
    });
    this.sendingRequest = true;
    this.users.sendUserOrgRequest(this.requestedOrg._id, this.userInfo.oktaId)
    .pipe(
      catchError((err: any) : Observable<any> => {
        this.sendingRequest = true;
        this.showRequestTeamMessage = false;
        this.toasterService.showToast('danger', 'Failed send request', 'Please try again in a few minutes')
        this.analyticsService.track('failed send request org access', {
          'user email': this.userInfo.email
        });
        return throwError(err);
      })
    )
      .subscribe(val => {
      this.showRequestTeamMessage = false;
      this.analyticsService.track('success send request org access', {
        'user email': this.userInfo.email
      });
      this.toasterService.showToast('success', 'Your request has been sent successfully');
    })
  }

  getTotalLiveStoriesCount() {
    this.pagesService.count({status: 'Live'})
    .pipe(
      catchError((err: any) : Observable<any> => {
        return throwError(err);
      })
    ).subscribe(res => this.totalLiveStoriesCount = res.count === 0 ? 0 : 1);
  }

  openHelpPage() {
    this.router.navigateByUrl('/pages/help');
    this.analyticsService.sendHubspotInternalEvent('clicked help', { date:  moment(new Date()).utc().startOf('day').format('DD/MM/YYYY')});
    this.analyticsService.sendHubspotProperty({ clicked_help_date:  moment(new Date()).utc().startOf('day').valueOf() });
  }

  wrongEnvAdminMessage() {
    if (window.location.href.includes('localhost')) return;
    this.dialogService.open(DiscardWizardDialog, {
      context: {
        body: '<strong>Storydoc admin information:</strong> <br><br> Wrong environment! <br> Do you want to open the correct one?',
        primaryText: 'Yes',
        secondaryText: 'No'
      }
    }).onClose.subscribe(value => value ? this.redirectToCorrectEnvironment() : null);
  }

  updateToV4Confirmation() {
    this.dialogService.open(DiscardWizardDialog, {
      context: {
        body: `Are you sure you want to update this organization?<br><strong>(Current version is ${this.isEditorV4User ? 'V4' : 'V3'})</strong>`,
        primaryText: 'Yes',
        secondaryText: 'No'
      }
    }).onClose.subscribe(value => value ? this.updateEditorV4User() : null);
  }


  get subscriptionTitle() {
    if(!this.orgInfo) return "Loading..."
    if(this.orgInfo.plan === 'trial') {
      return 'Free trial'
    } else {
      const plan = environment.paddle.plans.find(plan => plan.id === this.orgInfo.subscriptionPlanId);
      if(!plan || !plan.title) return "No plan found";
      return `
      Active ${plan.title} subscription,
      next payment on ${new Date(this.orgInfo.nextPayment).toLocaleDateString("en-US")}
      `;
    }
  }

  get plan() {
    if(!this.orgInfo || !this.orgInfo.subscriptionPlanId) return null;
    return environment.paddle.plans.find(plan => plan.id === this.orgInfo.subscriptionPlanId);
  }

  get showSubscribeNow() {
    return this.orgInfo && this.orgInfo.plan === 'trial';
  }

  get showSettingsButton() {
    if(!this.orgInfo) return false;
    return this.administrator && this.orgInfo; //  b2b & b2c can see this link. && (this.orgInfo.isSelfServ !== true || this.adminCore);
  }

  get showSubscriptionBadge() {
    return this.orgInfo && this.orgInfo.plan && this.orgInfo.subscriptionPlanId;
  }

  get isCouponActive() {
    return this.subscriptionService.getCookie('sd-paddle-coupon');
  }

}
