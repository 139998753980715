import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

import { catchError } from 'rxjs/operators';
import { from, Observable, throwError } from 'rxjs';
interface FAQ {
    question: string;
    answer: string;
}

@Injectable({
  providedIn: 'root'
})

export class SubscriptionService {

  constructor(private http: HttpClient) { }


  faq: FAQ[] = [
    {question: 'How to get a refund?', answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a vestibulum arcu, eget euismod elit. Praesent vulputate mollis leo sed rutrum. In porttitor metus ac elementum aliquam.'},
    {question: 'How many revisions is included?', answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a vestibulum arcu, eget euismod elit. Praesent vulputate mollis leo sed rutrum. In porttitor metus ac elementum aliquam.'},
    {question: 'How I\'ll contact a designer?', answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a vestibulum arcu, eget euismod elit. Praesent vulputate mollis leo sed rutrum. In porttitor metus ac elementum aliquam.'},
    {question: 'I don\'t have a branding. What should I do?', answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed a vestibulum arcu, eget euismod elit. Praesent vulputate mollis leo sed rutrum. In porttitor metus ac elementum aliquam.'},
  ]
  getMore: string[] = [
    'CRM integrations and automations',
    'Live chat support',
    'Tailor-made branded templates, created by our team',
    'Unlimited dynamic variables',
    'Team onboarding & training'
  ]

  getCookie(name: string){
    return `; ${document.cookie}`
    .split(`; ${name}=`)
    .pop()
    .split(';')
    .shift();
  }

  deleteCookie( name: string, path: string, domain: string ) {
    if( this.getCookie( name ) ) {
      document.cookie = name + "=" +
      ((path) ? ";path="+path:"")+
      ((domain)?";domain="+domain:"") +
      ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
  }

  get getFAQ() {
    return this.faq;
  }

  get getMoreList() {
    return this.getMore;
  }
}
