export const noGoogleFontCss = `

/* Design system temaplate 2.0.0 */
:root {
    /* theme colors */
    --theme-color-1: {{primaryColor}};
    --theme-color-2: {{secondaryColor}};
    --theme-color-3: {{themeColor3}};
    --theme-color-4: {{themeColor4}};

    --general-font: '{{font}}', sans-serif;
    --general-text-color:	{{paragraphColor}};

    /* backgrounds */
    --background-main-color: {{backgroundColor}};
    --background-secondary-color: {{backgroundColor2}};
    /* --background-attention-color: var(--theme-color-1) */

    /* links */
    --hyperlinks-text-color: {{hyperlinkColor}};
    --hyperlinks-text-hover-color: {{hyperlinkHoverColor}};
    --hyperlinks-underline: {{hyperlinkUnderline}};
    /* highlight */
    --highlight-text-color: {{highlightTextColor}};
    --highlight-background-color: {{highlightColor}};
    /* rkpi */
    --rkpi-font-size: {{rkpiFontSize}};
    /* button */
    --button-border-radius: {{buttonRadius}};
    --button-background-color: {{buttonBackgroundColor}};
    --button-background-hover-color: {{buttonBackgroundHoverColor}};
    --button-text-color: {{buttonColor}};
    --button-text-hover-color: {{buttonHoverColor}};

    /* titles */
    --title-color: {{titleColor}};
    --title-font: {{titleFont}};
    --title-style: {{titleStyle}};
    --title-size: {{titleFontSize}};
    --title-mobile-size: {{mobileTitleFontSize}};
    --title-margin-bottom: {{#if titleMargin}}{{titleMargin}}{{/if}}{{#unless titleMargin}}0px;{{/unless}};
    /* subtitles */
    --subtitle-color: {{subtitleColor}};
    --subtitle-font: {{subtitleFont}};
    --subtitle-style: {{subtitleStyle}};
    --subtitle-size: {{subtitleFontSize}};
    --subtitle-mobile-size: {{mobileSubtitleFontSize}};
    --subtitle-margin-bottom: {{#if subtitleMargin}}{{subtitleMargin}}{{/if}}{{#unless subtitleMargin}}0px;{{/unless}};
    /* paragraphs */
    --paragraph-color: {{paragraphColor}};
    --paragraph-font: {{paragraphFont}};
    --paragraph-style: {{paragraphStyle}};
    --paragraph-size: {{paragraphFontSize}};
    --paragraph-mobile-size: {{mobileParagraphFontSize}};
    --paragraph-margin-bottom: {{#if paragraphMargin}}{{paragraphMargin}}{{/if}}{{#unless paragraphMargin}}0px;{{/unless}};

}



/* General elements */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');

* {
  box-sizing: border-box;
}

html,
body {
  scroll-behavior: smooth;
  margin: auto;
  padding: 0;
}
body {
	font-family: var(--general-font);
  color: var(--general-text-color);
  /* background-color: var(--background-main-color); */
}

h1, h1 > p {
  font-size: var(--title-size);
  color: var(--title-color);
  font-family: var(--title-font);
  font-weight: var(--title-style);
  margin: 0px;
  margin-bottom: var(--title-margin-bottom);
}

h2, h2 > p {
  font-size: var(--title-size);
  color: var(--title-color);
  font-family: var(--title-font);
  font-weight: var(--title-style);
	margin: 0px;
  margin-bottom: var(--title-margin-bottom);
}

h3, h3 > p {
  font-size: var(--subtitle-size);
  color: var(--subtitle-color);
  font-family: var(--subtitle-font);
  font-weight: var(--subtitle-style);
  margin: 0px;
  margin-bottom: var(--subtitle-margin-bottom);
}

p, p > p {
  font-size: var(--paragraph-size);
  color: var(--paragraph-color);
  font-family: var(--paragraph-font);
  font-weight: var(--paragraph-style);
	margin: 0px;
  margin-bottom: var(--paragraph-margin-bottom);
}

@media (max-width: 767px) {

	h1, h1 > p {
    font-size: var(--title-mobile-size);
	}

  h2, h2 > p {
    font-size: var(--title-mobile-size);
	}

  h3, h3 > p {
    font-size: var(--subtitle-mobile-size);
  }

	p, p > p {
    font-size: var(--paragraph-mobile-size);
  }
}


/* story content width */
.container.wide {
   max-width: 1920px;
}

/* components background */
body .component:nth-child(2n+1),
div[class*="component"]:nth-child(2n+1)::after {
    background-color: var(--background-secondary-color);
}
body .component:nth-child(2n),
  div[class*="component"]:nth-child(2n)::after {
    background-color: var(--background-main-color);
}
/* body:not(.fullPage) .component {
    background-color: var(--background-main-color);
} */

/* For media preview only, the only option to have the media-component directly under body */
body:not(.fullPage) > .media-component {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC);
}
/* dont allow overflow */
body:not(.fullPage) > .media-component > [data-id] {
  overflow: hidden;
}


/* links */
a {
  text-decoration: none;
}
a:link,
a:visited,
a:active { color: var(--hyperlinks-text-color); }
a:hover {
	color: var(--hyperlinks-text-hover-color);
	transition: 0.3s;
  font-weight: 700;
  text-decoration: none;
}
{{#if hyperlinkUnderline}}
 [data-sd-trigger] a {
  text-decoration: var(--hyperlinks-underline);
 }
  [data-sd-trigger] a:hover {
  text-decoration: var(--hyperlinks-underline);
 }
 .button[data-sd-trigger] a, .button[data-sd-trigger] a:hover, a.tab__link, a.tab__link:hover, .tab__left a, .tab__left a:hover, .tabs-cnt a, .tabs-cnt a:hover {
  text-decoration: none;
 }
{{/if}}

/* progress bar */

.header123 {
  position: fixed;
  top: 0;
  z-index: 7;
  width: 100%;
}

.progress-container {
  width: 100%;
  height: 6px;
}

.progress-bar {
  height: 6px;
  background: var(--theme-color-1);
  width: 0%;
}

/* Storydoc Footer */
footer {
height: 70px;
background-color: #f9f9f9;
z-index: 7;
line-height: normal;
position:relative;
}

footer p {
margin:0;
text-align: center;
font-family: 'Lato', sans-serif;
font-weight: 400;
font-size: 15px;
height:100%;
line-height: 72px;
vertical-align:middle;
color: #333;
}

footer img {
height:16px;
margin:-2px 0 0 4px;
}

/* highlight feature */
.highlight{
    display:inline;
    position: relative;
    background-size: 200% auto;
    background-image: linear-gradient(to right, rgba(255,0,0,0) 50%,var(--highlight-background-color) 50%);
    background-position: 0% 0;
    animation-duration: 1s;
    animation-delay: 0.8s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    padding-left: 0.15em;
    margin-left: -0.15em;
    white-space: pre-wrap;
    padding-right: 0.15em;
    margin-right: -0.15em;

}
[dir="rtl"] .highlight {
    background-image: linear-gradient(to left, rgba(255,0,0,0) 50%,var(--highlight-background-color) 50%);
    background-position: 100%;
    animation-direction: reverse;
}
.highlight.activehigh{
   animation-name: highlight;
}
@keyframes highlight {
    from {background-position: 0% 0;}
    to {background-position: -100% 0; color: var(--highlight-text-color); font-weight: inherit}
}


/* RKPI */
[data-start-counter-class] {
  font-size: var(--rkpi-font-size);
}

/* IMAGE SHADOW */
.image_shadow {
	border-radius: 5px;
  box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.25);
}

/* Button */
.button,
a.button {
    position: relative;
    color: var(--button-text-color);
    background: var(--button-background-color);
    font-size: 16px;
    font-weight: 700;
    display: inline-flex;
    height: 50px;
    width: 200px;
    border-radius: var(--button-border-radius);
    box-shadow: 2px 2px 5px 0 rgb(0, 0, 0, 0.25);
    margin-top: 20px;
    margin-bottom: 20px;
    text-decoration: none;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.button:hover {
    color: var(--button-text-hover-color);
    background: var(--button-background-hover-color);
}
/* Layout */
.container.wide {
  max-width: 1920px;
}
.image-preload {
  display: none;
}
`
