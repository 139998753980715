import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { OktaAuthService } from '@okta/okta-angular';
import { AnalyticsService } from './services/analytics.service';
import mixpanel from 'mixpanel-browser'
import { environment } from '../environments/environment';

@Component({
  template: `<div>{{error}}</div>`
})
export class OktaCallbackComponent implements OnInit {
  error: string;

  constructor(private okta: OktaAuthService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {}

  async ngOnInit(): Promise<void> {
    try {

      // Custom functionality for catching errors in url.
      const qp = this.activatedRoute.snapshot.queryParams;
      if(Object.keys(qp).length == 0) {
        // if calls the implicit/callback without any parameters - redirect to homepage
        this.router.navigateByUrl('/');
        return;
      } else if(qp && qp.error) {
        // if calls the implicit/callback with an error - notify and sign out
        this.errorOccured(qp);
        return;
      }

      const originalUri = sessionStorage.getItem('okta-app-url');
      if (originalUri) this.okta.setOriginalUri(originalUri);

      // Okta functionality
      await this.okta.handleLoginRedirect();

    } catch (e) {
      // if error occures - notify and sign out
      this.errorOccured(e);
    }
  }

  errorOccured(err) {
    if(err && err["name"] === 'AuthApiError' && err["xhr"] && err["xhr"]["status"] === 400) {
      // dont alert on PCKE verification failed issues
    }
    else if(err && err["name"] === 'AuthSdkError' && err["errorSummary"] === 'The JWT was issued in the future') {
      this.track('failed to login to okta', {
        'failure message': 'The system clock of the user is not synchronized.'
      });
      alert ('Your computer system clock is not synchronized. Please sync it and try to login again.')
    }
    else {
      this.track('failed to login to okta', {
        'failure message': JSON.stringify(err)
      });
      alert('Error occured while logging in\nPlease contact support@storydoc.com')
    }
    this.okta.token.getWithRedirect();

    // Examples of failures:
    // {"name":"AuthApiError","xhr":{"responseText":"{\"error\":\"invalid_grant\",\"error_description\":\"PKCE verification failed.\"}","status":400,"responseType":"json","responseJSON":{"error":"invalid_grant","error_description":"PKCE verification failed."}}}
    // {"name":"AuthSdkError","errorCode":"INTERNAL","errorSummary":"OAuth flow response state doesn't match request state","errorLink":"INTERNAL","errorId":"INTERNAL","errorCauses":[]}
    // {"name":"AuthApiError","xhr":{}}
    // {"name":"AuthApiError","xhr":{"responseText":"{\"error\":\"invalid_grant\",\"error_description\":\"The authorization code is invalid or has expired.\"}","status":400,"responseType":"json","responseJSON":{"error":"invalid_grant","error_description":"The au
    // {"name":"AuthSdkError","errorCode":"INTERNAL","errorSummary":"Unable to retrieve OAuth redirect params from storage","errorLink":"INTERNAL","errorId":"INTERNAL","errorCauses":[]}
  }

  track(event, payload) {
    let mixpanelSettings: any = {
      // TODO: fix api-eu
      // api_host: "https://api-eu.mixpanel.com",
      persistence: 'localStorage'
    };
    mixpanel.init(environment.mixpanel, mixpanelSettings);
    return mixpanel.track(event, payload);
  }
}
