import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient) { }


  list(): Observable<any> {
    return this.http.get(environment.backend + '/v2/users');
  }

  me(): Observable<any> {
    return this.http.get(environment.backend + '/v2/users/me');
  }

  getOktaUser() {
    return this.http.get(environment.backend + '/v2/users/okta-me');
  }

  firstLogin(): Observable<any> {
    return this.http.post(environment.backend + '/v2/users/firstLogin', {});
  }

  changeRole(newRole, oktaId): Observable<any> {
    return this.http.post(environment.backend + `/v2/users/${oktaId}/changeRole`, {newRole});
  }

  resetPassword(oktaId): Observable<any> {
    return this.http.post(environment.backend + `/v2/users/${oktaId}/resetPassword`, {});
  }

  reactivateUser(oktaId): Observable<any> {
    return this.http.post(environment.backend + `/v2/users/${oktaId}/reactivateUser`, {});
  }

  updateUser(user, oktaId): Observable<any> {
    return this.http.post(environment.backend + `/v2/users/${oktaId}`, user);
  }
  updateUserOktaOrgID(user, oktaId): Observable<any> {
    return this.http.post(environment.backend + `/v2/users/${oktaId}/updateOrgId`, user);
  }

  sendUserOrgRequest(requestedOrgId, oktaId, user = {}): Observable<any> {
    return this.http.post(environment.backend + `/v2/users/${oktaId}/sendUserOrgRequest`, {requestedOrgId, user});
  }
  deleteUserOrgRequest(oktaId, user = {}): Observable<any> {
    return this.http.post(environment.backend + `/v2/users/${oktaId}/deleteUserOrgRequest`, user);
  }

  deleteUser(oktaId): Observable<any> {
    return this.http.delete(environment.backend + `/v2/users/${oktaId}`);
  }

  createUser(user): Observable<any> {
    return this.http.post(environment.backend + '/v2/users', user);
  }

  updateUserName(name: {name: string}): Observable<any> {
   return this.http.post(environment.backend + '/v2/users/update-name', name);
  }

  updateUserProfile(data): Observable<any> {
   return this.http.post(environment.backend + '/v2/users/update-profile', data);
  }


}
