import { Injectable } from '@angular/core';
import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import { from, of, Observable, BehaviorSubject, combineLatest, throwError } from 'rxjs';
import { tap, catchError, concatMap, shareReplay, map, retryWhen, delay, take, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../environments/environment';
import { OktaAuthService } from '@okta/okta-angular';
import jwt_decode from 'jwt-decode';
import Cookies from 'js-cookie'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  adminCore;
  currentUser$ = new BehaviorSubject<any>({});

  constructor(private router: Router,
              private auth: OktaAuthService) {

    this.getAdminCore$().subscribe(adminCore => {
      this.adminCore = adminCore;
    })
  }

  getAdminCore$() {
    return this.getUser$()
    .pipe(
      map(user => user.groups && user.groups.includes('DoTell employees'))
    );
  }

  getCreatorLite$() {
    if(this.adminCore) {
      if(this.userType == 'user') return from([false]);
      else return from([true]);
    } else {
      return this.getUser$()
      .pipe(
        map(user => user.groups && user.groups.includes('Creator lite'))
      );
    }

  }

  isOrgAdmin$(): Observable<any> { // actually behaves like a creator
    if(this.adminCore) {
      if(this.userType == 'user' || this.userType == 'creatorLite') return from([false]);
      else return from([true]);
    } else {
      return this.getUser$().pipe(
        map(user => user.groups && user.groups.includes('Org admin') || user.groups.includes('Administrator'))
      )
    }

  }

  isAdministrator$(): Observable<any> {
    if(this.adminCore) {
      if(['user', 'orgAdmin', 'creatorLite'].includes(this.userType)) return from([false]);
      else return from([true]);
    } else {
      return this.getUser$().pipe(
        map(user => user.groups && user.groups.includes('Administrator'))
      )
    }

  }

  isAdmin$(): Observable<any> {
    if(this.adminCore) {
      if(this.userType == 'user' || this.userType == 'orgAdmin' || this.userType == 'creatorLite') return from([false]);
      return from([true]);
    } else {
      return from([false]);
    }
  }

  isAdminCore$(): Observable<any> {
    return from([this.adminCore])
  }

  getUserType$(): Observable<any> {
    if(this.adminCore) {
      return from([this.userType]);
    } else {
      return this.getUser$().pipe(
        mergeMap(user => {
          let userType = 'user';
          // if(user.groups.includes('DoTell employees')) userType = 'storydoc admin';
          if(user.groups.includes('Creator lite')) userType = 'creatorLite';
          if(user.groups.includes('Org admin')) userType = 'orgAdmin';
          if(user.groups.includes('Administrator')) userType = 'administrator';
          return from([userType]);
        })
      )
    }
  }



  adminOrgIdKey = 'dotell-admin-orgId-2';
  adminUserTypeKey = 'dotell-admin-user-type-2';

  getOrgId$(): Observable<any> { // src/app/@theme/components/header/header.component.ts
    let adminOrgId = Cookies.get(this.adminOrgIdKey) || window.localStorage.getItem(this.adminOrgIdKey);
    return this.getUser$().pipe(
      tap(user => {
        if(!user.orgId) this.router.navigateByUrl('/welcome');
      }),
      map(user => {
        return (adminOrgId && user.groups && user.groups.includes('DoTell employees')) ? adminOrgId : user.orgId;
      })
    )
  }

  setOrgId(value) {
    Cookies.remove(this.adminOrgIdKey);
    Cookies.set(this.adminOrgIdKey, value);
    window.localStorage.setItem(this.adminOrgIdKey, value);
  }

  get userType() {
    const userType = Cookies.get(this.adminUserTypeKey) || window.localStorage.getItem(this.adminUserTypeKey);
    return userType || "user";
  }

  set userType(value) {
    Cookies.remove(this.adminUserTypeKey)
    Cookies.set(this.adminUserTypeKey, `${value}`);
    window.localStorage.setItem(this.adminUserTypeKey, `${value}`);
  }

  getAnalyticsLink$(): Observable<any> {
    return this.getUser$().pipe(
      map(user => user.analyticsLink)
    )
  }

  getTokenSilently$(options?): Observable<string> {
    return from(this.auth.tokenManager.get('accessToken'))
    .pipe(map((at: any) => at.accessToken))
    // tokenManager has delay on login, therefore the retries
    .pipe(retryWhen(errors => errors.pipe(delay(50), take(10))))
  }

  getUser$(): Observable<any> {
    return from(this.auth.tokenManager.get('accessToken'))
    .pipe(map((at: any) => jwt_decode(at.accessToken)))
    // tokenManager has delay on login, therefore the retries
    .pipe(retryWhen(errors => errors.pipe(delay(50), take(10))))
  }

  updateCurrentUser(user) {
    this.currentUser$.next(user);
  }

  logout() {
    this.auth.signOut();
  }

  renewToken() {
    return from(this.auth.tokenManager.renew('accessToken'));
  }

  login(originalUrl: string = null) {
    // Save current URL before redirect
    sessionStorage.setItem('okta-app-url', originalUrl || this.router.url);

    // Launches the login redirect.
    this.auth.token.getWithRedirect({
      scopes: ['openid', 'email', 'profile']
    });
  }
}
