import { Injectable } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CropperComponent } from '../../components/cropper/cropper.component';
import { ColorPickerDialogComponent } from '../../components/edit-component-dialog/color-picker-dialog/color-picker-dialog.component';
import { MediaLibraryDialogComponent } from '../../components/media-library-dialog/media-library-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class EditorFacade {
  constructor(private dialogService: NbDialogService) { }

  chooseImage(variable: any, admin: boolean = false) {
    return this.dialogService.open(MediaLibraryDialogComponent, {
      closeOnBackdropClick: false,
      closeOnEsc: false,
      context: { variable }
    }).onClose
      .pipe(switchMap((res) => {
        if (!res) return from([ { media: true } ]);
        return this.openCropper({ variable, url: res.url, admin })
      }))
  }

  openCropper(context: { variable: any, url: string, admin: boolean }) {
    return this.dialogService.open(CropperComponent, { context }).onClose
  }

  chooseColor(context) {
    return this.dialogService.open(ColorPickerDialogComponent, { context }).onClose
  }
}
