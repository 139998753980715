import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
// const Handlebars = require("handlebars");
// import * as Handlebars from "handlebars";
import { html } from "./layouts/1.0.0.template.html";
import { websiteHTML } from './layouts/website.template.html';
import {css} from "./layouts/2.0.0.layout.css";

import { noThirdPartHtml } from './layouts/1.0.1.template.html';
import {noGoogleFontCss} from "./layouts/2.0.1.layout.css";

declare const Handlebars: any;
// const fs = require('fs');
// const html = fs.readFileSync('./layouts/1.0.0.template.html', 'utf8');
// const css = fs.readFileSync('./layouts/2.0.0.layout.css', 'utf8');
// const html = '<h1>hw</h1>';
// const css = 'h1{color: red;}';
Handlebars.registerHelper('and', function(v1, v2, options) {
  if(v1 && v2) {
    return options.fn(this);
  }
  return options.inverse(this);
});
Handlebars.registerHelper('var',function(name, value, context){
  this[name] = value;
});
Handlebars.registerHelper('stringify',function(value, context){
  return JSON.stringify(value);
});
Handlebars.registerHelper('equals',function(v1, v2, options){
  return v1 == v2;
});
Handlebars.registerHelper('gt',function(v1, v2, options){
  return v1 > v2;
});
Handlebars.registerHelper('lt',function(v1, v2, options){
  return v1 < v2;
});
Handlebars.registerHelper('thousands',function(v1, v2, options){
  return v1.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
});

@Injectable({
  providedIn: 'root'
})
export class HandlebarsService {

  compile;
  template;
  designSystemTemplate;
  websiteTemplate;
  noGoogleFontDesignSystemTemplate;
  noThirdPartTemplate;

  constructor(private http: HttpClient) {
    this.compile = Handlebars.compile;
    this.template = Handlebars.compile(html);
    this.websiteTemplate = Handlebars.compile(websiteHTML);
    this.designSystemTemplate = Handlebars.compile(css);
    this.noThirdPartTemplate = Handlebars.compile(noThirdPartHtml);
    this.noGoogleFontDesignSystemTemplate = Handlebars.compile(noGoogleFontCss);
  }

  renderHTML(data) {
    if (this.isWebsiteTemplate(data)) return this.websiteTemplate(data);
    if(this.isDisabledThirdPartOrg(data.system.orgId, data.settings)) return this.noThirdPartTemplate(data)
    return this.template(data);
  }

  renderCSS(story) {
    const designSystem = story.settings.designSystemV2;
    if(this.isDisabledThirdPartOrg(story.orgId, story.settings)) return this.noGoogleFontDesignSystemTemplate(designSystem)
    return this.designSystemTemplate(designSystem);
  }

  render(content, data) {
    if(!content) content = "";
    const template = this.compile(content);
    return template({vars: data});
  }

  testRender(html, data) {
    try {
      const template = Handlebars.compile(html);
      const renderedHTML = template(data);
      return true;
    } catch (e) {
      return false;
    }
  }

  isWebsiteTemplate({system, settings}): boolean {
    return system.orgId === '36a3a2a42b5b702f' && !settings.useDefaultTemplate;
  }

  isDisabledThirdPartOrg(orgId: string, settings: any): boolean {
    return ["277e0b70be103ee769e7a3133ab76ad6"].includes(orgId) && !settings.useDefaultTemplate;
  }

}
