import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { AuthService } from './auth.service';
import { ToasterService } from './services/toaster.service';
import { environment } from '../environments/environment';
import { Observable, throwError, combineLatest } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor {

  constructor(private auth: AuthService,
              private toasterService: ToasterService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if(!req.url.startsWith(environment.backend)) return next.handle(req);
    return combineLatest(this.auth.getTokenSilently$(), this.auth.isAdminCore$(), this.auth.getOrgId$()).pipe(
      mergeMap(([token, admin, orgId]) => {
        const headers: any = { Authorization: `Bearer ${token}` };
        if(admin) headers.orgid = orgId;
        const tokenReq = req.clone({
          setHeaders: headers
        });
        return next.handle(tokenReq).pipe(
          catchError((error: HttpErrorResponse): Observable<any> => {
            if (error && error.status === 401) {
              this.toasterService.showToast('danger', 'Please login')
              this.auth.login();
            }
            return throwError(error);
          })
        );
      }),
      catchError(err => throwError(err))
    );
  }
}
