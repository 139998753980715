import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { AnalyticsService } from '../../services/analytics.service';
import { AccountsService } from '../../services/accounts.service';
import { ToasterService } from '../../services/toaster.service';
import { repeat, delay, finalize } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import * as moment from 'moment';


@Component({
  selector: 'ngx-check-payment-status-dialog',
  templateUrl: 'check-payment-status-dialog.component.html',
  styleUrls: ['check-payment-status-dialog.component.scss'],
})
export class CheckPaymentStatusDialogComponent {

  @Input() subscriptionAnalyticsObject;
  @Input() currentSubscriptionId;
  success = false;
  failure = false;
  subscription;

  track(event, params = {}) {
    this.analyticsService.track(event, {
      'screen': 'subscription-page',
      ...this.subscriptionAnalyticsObject,
      ...params
    });
  }

  constructor(protected ref: NbDialogRef<CheckPaymentStatusDialogComponent>,
              private toasterService: ToasterService,
              private analyticsService: AnalyticsService,
              private accountsService: AccountsService) {}

  ngOnInit() {
    this.subscription = this.accountsService.info(true)
    .pipe(delay(2500))
    .pipe(repeat(120))
    .pipe(finalize(() => {
      if(!this.success) {
        this.failed();
        this.track('failed paying customer', {
          'failure message': '5 minutes passed with no update'
        });
      }
    }))
    .subscribe(orgInfo => {
      if (this.currentSubscriptionId) return this.checkPaddleSubscriptionId(orgInfo);
      if (orgInfo.subscriptionPlanId) {
        const paddlePlan = environment.paddle.plans.find(plan => plan.id === orgInfo.subscriptionPlanId);
        const data = {};
        if (paddlePlan) {
          data[ 'plan' ] = paddlePlan.title;
          data[ 'billingPeriod' ] = paddlePlan.period;
          data[ 'monthlyValue' ] = paddlePlan.period === 'Monthly'
            ? paddlePlan.price
            : paddlePlan.price / 12;

          this.analyticsService.sendHubspotProperty({
            total_payment_amount: paddlePlan.price,
            number_of_seats: paddlePlan.seats,
            plan_billing_type: paddlePlan.period,
            plan_monthly_value: paddlePlan.period === 'Monthly' ? paddlePlan.price : paddlePlan.price / 12,
            plan_name: paddlePlan.title,
            trial_status: 'inactive'
          });
        }
        this.track('success paying customer');
        this.analyticsService.sendHubspotInternalEvent('success paying customer', data);
        this.analyticsService.sendHubspotProperty({ success_paying_customer: moment(new Date()).utc().startOf('day').valueOf() });
        this.success = true;
        this.clearSubscription();
      } else if(orgInfo.lastPaymentErrorMessage) {
        this.failed();
        this.track('failed paying customer', {
          'failure message': `lastPaymentErrorMessage: ${orgInfo.lastPaymentErrorMessage}`
        })
      }
    })

  }

  checkPaddleSubscriptionId(orgInfo) {
    if (orgInfo.subscriptionId !== this.currentSubscriptionId) {
      const paddlePlan = environment.paddle.plans.find(plan => plan.id === orgInfo.subscriptionPlanId);
      const data = {};
      if (paddlePlan) {
        data[ 'plan' ] = paddlePlan.title;
        data[ 'billingPeriod' ] = paddlePlan.period;
        data[ 'monthlyValue' ] = paddlePlan.period === 'Monthly'
          ? paddlePlan.price
          : paddlePlan.price / 12;

        this.analyticsService.sendHubspotProperty({
          total_payment_amount: paddlePlan.price,
          number_of_seats: paddlePlan.seats,
          plan_billing_type: paddlePlan.period,
          plan_monthly_value: paddlePlan.period === 'Monthly' ? paddlePlan.price : paddlePlan.price / 12,
          plan_name: paddlePlan.title,
          trial_status: 'inactive'
        });
      }
      this.track('success paying customer');
      this.analyticsService.sendHubspotInternalEvent('success paying customer', data);
      this.analyticsService.sendHubspotProperty({ success_paying_customer: moment(new Date()).utc().startOf('day').valueOf() });
      this.success = true;
      this.clearSubscription();
    }
  }

  clearSubscription() {
    if(this.subscription) this.subscription.unsubscribe();
  }

  failed() {
    this.clearSubscription();
    this.toasterService.showToast('danger', 'Error occured setting up your account, we will take care of that for you.', '');
    this.failure = true;
    return;
  }

  close() {
    window.location.href = '/pages/editor';
  }

  cancel() {
    this.ref.close(false);
  }

  ngOnDestroy() {
    this.clearSubscription();
  }

}
