import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';

import {
  OktaAuthGuard,
  // OktaCallbackComponent,
  OktaLoginRedirectComponent,
} from '@okta/okta-angular';

import { OktaCallbackComponent } from './callback.component';
import { SetupComponent } from './setup.component';

const routes: Routes = [
  {
    path: 'login',
    component: OktaLoginRedirectComponent
  },
  {
    path: 'implicit/callback',
    component: OktaCallbackComponent
  },
  {
    path: 'welcome',
    canActivate: [OktaAuthGuard],
    component: SetupComponent
  },
  {
    path: 'pages',
    canActivate: [OktaAuthGuard],
    canActivateChild: [OktaAuthGuard],
    loadChildren: () => import('app/pages/pages.module')
      .then(m => m.PagesModule),
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
