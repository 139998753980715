import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, from } from 'rxjs';

const leadCaptureUrl = "https://script.google.com/macros/s/AKfycbwNjldunc8g7UihIxydSbm7taj4oz6gN4bM2LjtuHQb-ElgYIXm_HcaHid_SxwrqWtm/exec";

@Injectable({
  providedIn: 'root'
})
export class WebsiteService {

  constructor(private http: HttpClient) { }

  writeLead(orgId, orgTitle, email, fullName, formName = 'Pricing - platform'): Observable<any> {
    let body = {};
    body['full-name'] = fullName;
    body['professional-email'] = email;
    body['company-name'] = orgTitle;
    body['org-id'] = orgId;
    body['form-name'] = formName;
    const formData = new FormData();
    Object.keys(body).forEach(key => formData.append(key, body[key]));
    return this.http.post(leadCaptureUrl, formData)
  }

}
