import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(private http: HttpClient) {
    this.proPlanIds = environment.paddle.plans.filter(plan => {if (plan.title === 'Pro') return plan.id; }).map(plan => plan.id)
  }

  orgInfo;
  accountCustomization$ = new BehaviorSubject<any>(null);
  editorV4UserStatus$ = new BehaviorSubject<any>(null);
  proPlanIds = [];
  isEditorV4User: boolean = false;

  get(): Observable<any> { // admin only
    return this.http.get(environment.backend + '/accounts');
  }

  search(search: string): Observable<any> { // admin only
    return this.http.post(environment.backend + '/accounts/search', {search});
  }

  info(force = false, update = true): Observable<any> {
    if(this.orgInfo && !force) return from([this.orgInfo]);
    return this.http.get(environment.backend + '/accounts/info')
      .pipe(
        tap(orgInfo => {
          if (update) this.orgInfo = orgInfo;
          if(this.orgInfo && this.orgInfo.parameters && this.orgInfo.parameters.isEditorV4) this.isEditorV4User = true;
        })
      );
  }

  updateOrgInfo(orgInfo) {
    this.orgInfo = orgInfo;
  }

  firstVersionCreation(): Observable<any> {
    return this.http.post(environment.backend + '/accounts/firstVersionCreation', {})
  }

  firstVersionShared(): Observable<any> {
    return this.http.post(environment.backend + '/accounts/firstVersionShared', {})
  }

  postTrialVisitDate(): Observable<any> {
    return this.http.post(environment.backend + '/accounts/postTrialVisit', {})
  }

  paymentPendingStatus(status = true): Observable<any> {
    return this.http.post(environment.backend + '/accounts/payment-pending', {status})
  }

  firstLogin(): Observable<any> {
    return this.http.post(environment.backend + '/accounts/firstLogin', {});
  }

  updateDsId(dsId, website, industry, usecase, additionalData): Observable<any> {
    return this.http.post(environment.backend + '/accounts/updateDsId', {dsId, website, industry, usecase, additionalData});
  }

  updateDsIdOnly(dsId): Observable<any> {
    return this.http.post(environment.backend + '/accounts/updateDsId', {dsId});
  }
  updateAdminCustomization(data) {
    return this.http.post(environment.backend + '/accounts/admin-customization', data);
  }

  createUser(user): Observable<any> {
    // user
    // {
    //   orgId,
    //   email,
    //   firstName,
    //   lastName,
    //   editor,
    //   selfServ
    // }
    return this.http.post(environment.backend + '/users/createUser', user);
  }

  createAccount(parameters) {
    return this.http.post(environment.backend + '/accounts', {parameters});

  }

  sendVerificationEmail() {
    return this.http.post(environment.backend + '/accounts/sendVerificationEmail', {
      redirectUrl: window.location.href + '?verified'
    });
  }

  getOrgVersionsCount() {
    return this.http.get(`${environment.backend}/versions/count`);
  }

  updatePlan(planId): Observable<any> {
    return this.http.post(environment.backend + `/accounts/updatePlan`, {planId});
  }

  cancelPlan(): Observable<any> {
    return this.http.post(environment.backend + `/accounts/cancelPlan`, {});
  }
  extendTrialPlan(): Observable<any> {
    return this.http.post(environment.backend + `/accounts/extendTrialPlan`, {});
  }

  addSeats(seats, description = undefined): Observable<any> {
    return this.http.post(environment.backend + `/accounts/addSeats`, {seats, description});
  }

  buySeats(seats): Observable<any> {
    return this.http.post(environment.backend + `/accounts/buySeats`, {seats});
  }

  customization(customization): Observable<any> {
    return this.http.post(environment.backend + '/accounts/customization', customization)
  }

  getInvoices(): Observable<any> {
    return this.http.get(environment.backend + `/accounts/getInvoices`);
  }

  getDomainOrg(): Observable<any> {
    return this.http.get(environment.backend + '/accounts/domainOrgs');
  }

  getRequestedUsers(): Observable<any> {
    return this.http.get(environment.backend + '/accounts/getRequestedUsers');
  }

  updateAccountCustomization(customization) {
    this.accountCustomization$.next(customization);
  }

  setEditorV4User(status): Observable<any> {
    const parameters = {
      isEditorV4: status
    }
    return this.http.post(environment.backend + '/accounts/updateOrgParameters', {parameters});
  }

  updateEditorV4User(status) {
    this.editorV4UserStatus$.next(status);
  }

  get isB2B() {
    if(!this.orgInfo) return false;
    return this.orgInfo.isSelfServ !== true || (!this.isSubscribed && this.isPaid && this.orgInfo.appsumoPlanId !== 'storydoc_tier1') || (this.orgInfo.isSelfServ && this.proPlanIds.includes(this.orgInfo.subscriptionPlanId));
  }
  get isPaid() {
    if(!this.orgInfo) return false;
    return this.orgInfo.plan === 'paid' || this.orgInfo.plan === undefined;
  }
  get isSubscribed() {
    if(!this.orgInfo) return false;
    return this.orgInfo.subscriptionId != null
  }

  get paddlePlan() {
    if (!this.orgInfo) return null;
    if (this.isAppSumo) {
      return environment.appSumo.plans.find(plan => plan.id === this.orgInfo.appsumoPlanId);
    }
    return environment.paddle.plans.find(plan => plan.id === this.orgInfo.subscriptionPlanId);
  }

  get periodText() {
    const plan: any = this.paddlePlan || {};
    return plan.period || 'Monthly';
  }

  get planUsersLimit() {
    if(!this.orgInfo) return 1;
    const { planLimit, additionalSeats } = this.getAccountSeats;
    return Number(planLimit) + Number(additionalSeats);
  }

  get getAccountSeats() {
    const currentPlan: any = this.paddlePlan;
    let planLimit          = currentPlan && currentPlan.seats ? currentPlan.seats : 1;
    let additionalSeats = this.orgInfo.additionalSeats || 0;

    return { planLimit, additionalSeats }
  }

  get seatPrice() {
    const plan: any = this.paddlePlan || {};
    return plan.seatPrice;
  }

  get isAppSumo() {
    return !!this.orgInfo.appsumoUuid;
  }

  get isStarter() {
    return !!(this.paddlePlan && this.paddlePlan.title === 'Starter');
  }

  get isPro() {
    return !!(this.paddlePlan && this.paddlePlan.title === 'Pro');
  }

  testingGroupA = 'A - with templates';
  testingGroupB = 'B - without templates';
  get testingGroup() {
     // hotfix - remove the AB testing
    return this.testingGroupA;
    if(!this.orgInfo) return this.testingGroupA;
    return ((this.orgInfo.testingNumber || 2) % 2 === 0) ? this.testingGroupA : this.testingGroupB;
  }
}
