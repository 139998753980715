
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Cookies from 'js-cookie'
import { AccountsService } from './services/accounts.service';
import { AnalyticsService } from './services/analytics.service';
import { AuthService } from './auth.service';
import { environment } from '../environments/environment';
import { mergeMap } from 'rxjs/operators';
import { from } from 'rxjs';

@Component({
  // selector: 'ngx-setup',
  // templateUrl: './setup.component.html',
  // styleUrls: ['./setup.component.scss']
  template: `<div></div>`
})
export class SetupComponent implements OnInit {
  utms = {};
  gclid;
  cid;
  validation;
  landingPage: string;
  screen = 'setup'
  ipInfo: any = {};

  constructor(
    private accountsService: AccountsService,
    private analyticsService: AnalyticsService,
    private auth: AuthService,
    private router: Router,
  ) { }

  track(event, params = {}) {
    this.analyticsService.track(event, {
      'screen': this.screen,
      ...params
    });
  }

  ngOnInit() {
    this.getIpInfo();
    const spinnerEl = document.getElementById('nb-global-spinner');
    if(spinnerEl) spinnerEl.style.display = 'flex';
    const cookiePrefix = 'storydoc_params_';
    document.cookie.split(';').filter(cookie => cookie.indexOf(cookiePrefix) > -1).map(cookie => {
      const [k, v] = cookie.trim().split('=');
      this.utms[k.replace(cookiePrefix, '')] = v;
    })
    this.gclid = this.getCookie('storydoc_gclid');
    this.cid = this.getCookie('storydoc_cid');
    this.validation = this.getCookie('storydoc_validation');
    this.landingPage = this.getCookie('storydoc_landing_page');

    this.utms['gclid']=this.gclid; //temp
    this.utms['cid']=this.cid; //temp
    this.utms['landingPage']=this.landingPage;

    this.auth.getUser$()
    .subscribe(user => {
      if(user.orgId) {
        Cookies.remove('orgId');
        return this.router.navigateByUrl('/pages/editor');
      }
      if(Cookies.get('orgId')) {
        // account already created, but had a problem refreshing
        return this.auth.login();
      }

      this.accountsService.createAccount(this.utms)
      .pipe(mergeMap((account: any) => {
        Cookies.set('orgId', account._id);
        if(user.sub.indexOf('@storydoc') > -1) return from([account]); // dont send our own events
        return from(this.sendToStpreadSheet(user, account));
      }))
      .subscribe(account => {
        return this.auth.login();
        // this.auth.renewToken()
        // .subscribe(res => {
        //   this.analyticsService.init();
        //   return this.router.navigateByUrl('/pages/editor');
        // });
      });
    })
    // this.accountsService
    // get cookies storydoc_utms, storydoc_gclid
    // Cookies.remove(this.adminOrgIdKey);
    // Cookies.set(this.adminOrgIdKey, value);


  }

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  sendToStpreadSheet(user, account) {
    let spreadSheetUrl = 'https://script.google.com/macros/s/AKfycbwYUaBtiTRwGzOOW8YshZaX9I9RJijwSoikFJ7Q-IWHEmknjw_dCcOcMpzHjNjj-vPRCg/exec'
    if(environment.env === 'production') spreadSheetUrl = 'https://script.google.com/macros/s/AKfycbzckb9sdTUZ1P0WAa-ejGgmnJpxzi44y0MjuALHvG2Ij1WBpBJ0YbKyXa_xU04MOUL3/exec';
    var formData = new FormData();
    formData.append('professional-email', user.sub);
    formData.append('full-name', user.name);
    formData.append('org-id', account._id);
    formData.append('gclid', this.gclid);
    formData.append('cid', this.cid);
    formData.append('email-validation', this.validation);
    formData.append('country-name', this.ipInfo.country_name || '');
    formData.append('city-name', this.ipInfo.city || '');

    for(let k in account.parameters) {
      formData.append(k, account.parameters[k]);
    }

    this.track('click send lead to spreadsheet');
    return fetch(spreadSheetUrl, { method: 'POST', body: formData})
    .then(res => {
      this.track('success send lead to spreadsheet');
      return account;
    })
    .catch(err => {
      this.track('failed send lead to spreadsheet')
    })
  }

  getIpInfo() {
    return fetch('https://geolocation-db.com/json/', { method: 'GET' })
    .then(data => data.json())
    .then(ipInfo => this.ipInfo = ipInfo)
    .catch(err => {
      this.track('failed to get ip info', {
        'failure message': err.message
      });
      return {};
    })
  }
}
