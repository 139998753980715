import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NbDialogRef } from '@nebular/theme';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError, map, pairwise, startWith } from 'rxjs/operators';
import { integrations } from '../../pages/profile/integrations/integrations-list';

// Services
import { AccountsService } from '../../services/accounts.service';
import { AnalyticsService } from '../../services/analytics.service';
import { ToasterService } from '../../services/toaster.service';

@Component({
  selector: 'ngx-admin-customization-dialog',
  templateUrl: 'admin-customization-dialog.component.html',
  styleUrls: ['admin-customization-dialog.component.scss'],
})
export class AdminCustomizationDialogComponent implements OnInit, OnDestroy {

  constructor(protected ref: NbDialogRef<AdminCustomizationDialogComponent>,
              private accountsService: AccountsService,
              private toasterService: ToasterService,
              private analyticsService: AnalyticsService) { }

  orgInfo;
  form: FormGroup
  formSubscription: Subscription
  allowedOrgKeys: string[] = [ 'hideHeaderBadge', 'hideFooterBadge', 'allowedIntegrations', 'title', 'piwik', 'analyticsDisable',  'firstLogin', 'hideSmallBadge', 'emailDomain' ];
  integrations = integrations;
  changedValues = {}
  canUpdate = false;
  loading = false;

  variables = [
    {"type":"toggle","name":"analyticsDisable","col": 6,"title":"Disable global analytics","default":"","tip":"Stories in this organization will not send analytics as a default","validation":{"required":false}},
    {"type":"toggle","name":"hideHeaderBadge", "col": 6,"title":"Hide header badge","default":"","tip":"Hide header badge for all stories","validation":{"required":false}},
    { "type": "toggle", "name": "hideFooterBadge", "col": 6, "title": "Hide footer badge", "default": "", "tip": "Hide footer badge for all stories", "validation": { "required": false } },
    { "type": "toggle", "name": "hideSmallBadge", "col": 6, "title": "Hide small badge", "default": "", "tip": "Hide small side badge for all stories", "validation": { "required": false } },
    { "type": "toggle", "name": "piwik","col": 6, "title": "Enable Piwik analytics", "default": "", "tip": "Change Google analytics to Piwik analytics", "validation": { "required": false } },
    { "type": "input", "name": "firstLogin","col": 12, "title": "Reset wizard", "default": "", "tip": "To reset wizard, set input to be empty", "validation": { "required": false } },
    { "type": "input", "name": "title","col": 12, "title": "Organization name", "default": "", "tip": "Change organization name", "validation": { "required": false } },
    { "type": "input", "name": "emailDomain","col": 12, "title": "Domain name", "default": "", "tip": "Change domain name", "validation": { "required": false } },
    { "type": "select", "name": "allowedIntegrations", "col": 12, "placeholder": "Select integration", "multiple": true, "title": "Allowed integrations", "options": this.mappedIntegrations, "default": "", "tip": "Enable or disable integrations", "validation": { "required": false } }
  ]

   track(event, params = {}) {
    this.analyticsService.track(event, {
      'screen': 'dashboard customization',
      ...params
    });
  }

  ngOnInit(): void {
    this.accountsService.info()
    .subscribe(orgInfo => {
      this.orgInfo = orgInfo;
      this.initForm();
    })
    this.formSubscription = this.form.valueChanges
    .pipe(
      startWith(this.form.value),
      pairwise(),
      map(([prev, curr]) => Object.keys(curr).find(key => prev[key] !== curr[key]))
    )
    .subscribe(key => {
      this.changedValues[ key ] = this.form.get(key).value
      this.canUpdate = true;
    })
  }

  initForm(): void {
    const formData: any = {}
    this.allowedOrgKeys.forEach(key => formData[key] = new FormControl(this.orgInfo[key] ? this.orgInfo[key] : null, []))
    this.form = new FormGroup(formData)
  }

  update() {
    this.loading = true;
    if (this.changedValues.hasOwnProperty('firstLogin')) this.changedValues[ 'dsId' ] = '';
    this.accountsService.updateAdminCustomization(this.changedValues)
    .pipe(
      catchError((err: any): Observable<any> => {
        this.track('failed admin customization');
        this.loading = false;
        this.toasterService.showToast('danger', 'Failed to update');
        return throwError(err);
      })
    )
    .subscribe(orgInfo => {
      this.accountsService.updateOrgInfo(orgInfo);
      this.track('success admin customization')
      this.loading = false;
      this.close();
      this.toasterService.showToast('success', 'Success to update');
    })
  }

  close() {
    this.ref.close();
  }

  get mappedIntegrations() {
    return this.integrations.filter(i => i.available).map(i => ({title: i.title, value: i.internalName}))
  }

  ngOnDestroy(): void {
    if(this.formSubscription) this.formSubscription.unsubscribe()
  }

}
