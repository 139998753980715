import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, combineLatest, throwError, of } from 'rxjs';
import { catchError, delay, retryWhen, mergeMap } from 'rxjs/operators';
import * as moment from 'moment';
import Cookies from 'js-cookie'


import { environment } from '../../environments/environment';
import mixpanel from 'mixpanel-browser'
import { AuthService } from '../auth.service';
import { AccountsService } from './accounts.service';
import { PagesService } from './pages.service';
import { UsersService } from './usersV2.service';
import { IntegrationsService } from './integrations.service';

declare const window;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  block = false;
  loaded = false;
  counter = 0;
  eventsQueue = [];

  constructor(private http: HttpClient,
              private accountsService: AccountsService,
              private pagesService: PagesService,
              private usersV2Service: UsersService,
              private integrationsService: IntegrationsService,
              private router: Router,
              private auth: AuthService) {

    let mixpanelSettings: any = {
      // TODO: fix api-eu
      // api_host: "https://api-eu.mixpanel.com",
      persistence: 'localStorage'
    };
    if(!environment.production) mixpanelSettings.debug = true;

    mixpanel.init(environment.mixpanel, mixpanelSettings);

    this.init();
  }

  oktaUser;
  init() {
    this.usersV2Service.getOktaUser().subscribe((user: any) => {
      this.oktaUser = user;

      const uid = user.id;
      mixpanel.identify(uid);

      const orgId = user.profile.dotellOrgId;
      if(!orgId) {
        if(this.router.url === '/welcome') return;
        return this.router.navigateByUrl('/welcome');
      } else {
        Cookies.remove('orgId');
      }

      const email = user.profile.email;
      const name = user.profile.firstName + (user.profile.lastName || '');
      // Block all employees account from being tracked on production
      if(email.includes('@storydoc.com') && environment.env === 'production') this.block = true
      mixpanel.people.set({
        '$email': email,
        'Name': name,
        'orgId': orgId
      });
      mixpanel.register({
        'user id': uid,
        'user name': name,
        'user email': email,
        'visit count': this.incrementor('visit count')
      });
      this.loadData();
    })

  }

  gtmAnalytics(eventAction: string, properties: any = {}) {
    if(window.dataLayer) {
      window.dataLayer.push({'event':'conversion','event-category':'click','event-action': eventAction, 'properties': {...properties}});
    }
  }

  loadData() {
    // console.log("has_opted_out_tracking", mixpanel.has_opted_out_tracking());
    this.loadOrgInfo();

    combineLatest(
      this.pagesService.count({status: 'Live'}),
      this.pagesService.count(),
      this.accountsService.getOrgVersionsCount()
    ).subscribe(([liveCount, allCount, versionsCount]) => {
      const versionsCountObj: any = versionsCount;
      mixpanel.register({
        'organization active stories': liveCount.count,
        'organization stories': allCount.count,
        'organization versions': versionsCountObj.count
      })
    });
    combineLatest(
      this.pagesService.count({onlyOwnStories: true, status: 'Live'}),
      this.pagesService.count({onlyOwnStories: true})
    ).subscribe(([liveCount, allCount]) => {
      mixpanel.register({
        'user active stories': liveCount.count,
        'user stories': allCount.count
      })
    });


    this.auth.getUserType$().subscribe(userType => {
      mixpanel.register({
        'user type': userType
      });
    });

  }

  get emailVerified() {
    const primaryCreds = this.oktaUser.credentials.emails.find(c => c.type === 'PRIMARY') || {};
    return primaryCreds.status === 'VERIFIED';
  }

  incrementor(key) {
    const value = mixpanel.get_property(key);
    return (value && typeof(value) == 'number') ? value +1 : 1;
  }

  // workaround to wait until org information returns,
  // TODO: consolidate all information resources to 1 low-latency call and finish loading once its loaded
  loadedFinished() {
    this.loaded = true;
    for(let e of this.eventsQueue) {
      this.track(e.event, e.payload);
    }
    this.eventsQueue = [];
  }

  loadOrgInfo() {
    this.accountsService.info()
    .pipe(
      catchError((err: any) : Observable<any> => {
        this.loadedFinished();
        this.track('failed load organization info', {
          'failure message': err.message
        });
        return throwError(err);
      })
    )
    .subscribe(org => {
      if(!org) {
        this.counter += 1;
        if(this.counter < 10) this.loadOrgInfo();
        this.track('failed load organization info', {
          'failure message': 'org returned empty'
        });
        return;
      }
      const isFreeTrial = (org.plan && org.plan === 'trial');
      const plan = environment.paddle.plans.find(plan => plan.id === org.subscriptionPlanId);
      const data = {
        'organization id': org._id,
        'organization name': org.title,
        'organization created': org.createdAt,
        'organization free trial': isFreeTrial,
        'organization is self serv': org.isSelfServ,
        'organization type': org.isSelfServ ? 'Self serve' : 'B2B',
        'organization appsumo': !!org.appsumoUuid,
        'organization verified': !!org.verifiedAt || this.emailVerified,
        'user is self serv': org.isSelfServ, // keep for consistency, not correct as not on the user scope
        'website': org.website,
        'industry': org.industry,
        'testing group': this.accountsService.testingGroup,
        'editor version': this.accountsService.isEditorV4User ? '4' : '3'
      };
      if (!!org.appsumoUuid) data[ 'appsumo user type' ] = org.appsumoPlanId;
      for(let k in org.parameters) {
        data[k] = org.parameters[k];
      }
      if(org.trialEnd && isFreeTrial) data['days to end free trial'] = moment(org.trialEnd).diff(moment(), 'days');
      if(plan && !isFreeTrial) {
        data['organization subscription plan name'] = plan.title;
        data['organization subscription plan period'] = plan.period;
      }
      mixpanel.register(data);
      this.loadedFinished();
      this.track('open app');
    });
  }

  track(event, payload = {}): Observable<any> {
    if(this.block) return;
    if(!this.loaded) {
      this.eventsQueue.push({ event, payload });
      return;
    }
    return mixpanel.track(event, {
      ...this.baseProperties,
      ...payload
    });
  }

  trackStory(event, page, payload = {}) {
    const isFastVersioning = true;
    // const isFastVersioning = page.settings.fs && page.settings.fs['fs-vars'];
    let template = {};
    if(page.settings.template) {
      const templateSettings = page.settings.template;
      template = {
        'default template cover video': templateSettings.defaultCoverVideo,
        'industry': templateSettings.industry ? templateSettings.industry : '',
        'website': templateSettings.website ? templateSettings.website : '',
        'selected template name': templateSettings.selectedTemplateName,
        'selected template category': templateSettings.templateCategory,
        'template design status': templateSettings.themeDesignStatus,
        'start from the website': templateSettings.isStartFromWebsiteFlow,
        'website signup': templateSettings.isStartFromWebsiteFlow ? "Yes" : 'No',
        'wizard flow': templateSettings.isWizardFlow,
        'go through the wizard': templateSettings.isWizardFlow ? 'Yes' : 'No',
        'selected usecase': templateSettings.selectedTemplateUseCase ? templateSettings.selectedTemplateUseCase : '',
        'company size': (templateSettings.companySize || ''),
        'presentation tool':  (templateSettings.presentationTool || ''),
        'why storydoc':  (templateSettings.whyStorydoc || ''),
      }

    }
    this.track(event, {
      'story name': page.settings.title,
      'story created': page.createdAt,
      'story type': isFastVersioning ? 'fast versioning' : 'regular',
      'story status': page.status,
      'story components number': page.pageComponentsLength || (page.pageComponents || []).length,
      'editor version': page.slides ? '4' : '3',
      ...payload,
      ...template
    })
  }

  trackVersion(event, page, version, payload = {}) {
    const expireAt = version.isPublic ? null : version.expireAt
    this.trackStory(event, page, {
      'story version name': version.data.title,
      'story version created': version.createdAt,
      'story version expire': expireAt,
      ...payload
    })
  }

  trackComponent(event, page, component, payload = {}) {
    this.trackStory(event, page, {
      'component title': component.title,
      'component category': component.category,
      ...payload
    })
  }

  trackPageComponent(event, page, pageComponent, pageComponentIndex, version = null, payload = {}) {
    const data = {
      'slide number': pageComponentIndex+1,
      'slide title': pageComponent.libraryTitle,
      'slide category': pageComponent.libraryCategory,
      'slide type': pageComponent.libraryType,
      ...payload
    };
    if(version) this.trackVersion(event, page, version, data);
    else this.trackStory(event, page, data);

  }

  register(key, value) {
    const obj = {}
    obj[key] = value;
    mixpanel.register(obj);
  }

  unregister(keys) {
    for(let key of keys) {
      mixpanel.unregister(key);
    }
  }


  get baseProperties() {
    return {
      "dotell version": "37",
    }
  }


  sendHubspotInternalEvent(event: string, props: { [ key: string ]: string|number } = {}) {
    this.gtmAnalytics(event, props);
    this.integrationsService.postAnalyticsEvent(event, props)
    .pipe(
      retryWhen(errors => errors.pipe(mergeMap((err, i) => {
        if (err.status !== 409 && err.status !== 401) return throwError(err);
        return i >= 3 ? throwError(err) : of(err)
      }), delay(1000))),
    ).subscribe({
      error: err => {
        this.track('Failed to send Hubspot internal event', {
          'failure message': JSON.stringify(err)
        });
      }
    })
  }

  sendHubspotProperty(property: {[key:string]: string|number} = {}) {
    this.integrationsService.postHubspotProperty(property)
    .pipe(
      retryWhen(errors => errors.pipe(mergeMap((err, i) => {
        if (err.status !== 409 && err.status !== 401) return throwError(err);
        return i >= 2 ? throwError(err) : of(err)
      }), delay(1000))),
    ).subscribe({
      error: err => {
        this.track('Failed to update Hubspot contact property', {
          'failure message': JSON.stringify(err)
        });
      }
    })
  }

}
