import { Component, OnInit } from '@angular/core';



@Component({
  selector: 'ngx-mobile-message-dialog',
  templateUrl: './mobile-message-dialog.component.html',
  styleUrls: ['./mobile-message-dialog.component.scss'],
})
export class MobileMessageDialog implements OnInit {

  constructor() {}

  ngOnInit() {

  }
}
