import { Injectable } from '@angular/core';
import {
  NbToastrService,
  NbGlobalPhysicalPosition,
  NbComponentStatus
} from '@nebular/theme';


@Injectable({
  providedIn: 'root'
})
export class ToasterService {

  constructor(private toastrService: NbToastrService) { }


  showToast(type: NbComponentStatus, title: string, body: string = '') {
    const config = {
      status: type,
      destroyByClick: true,
      duration: 2000,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: true,
    };

    this.toastrService.show(
      body,
      title,
      config
    );
  }

}
