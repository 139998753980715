import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { personalData } from './personal-data.interface';
import { accountVariables } from './account-variables';

@Injectable({
  providedIn: 'root'
})
export class SenderDataService {
  constructor() { }

  personalInfoData$ = new BehaviorSubject<personalData | null | undefined>(null);


  updatePersonalInfoData(info: personalData) {
    this.personalInfoData$.next(info);
  }

  getAccountVariablesMapping() {
    const mapped = {};
    accountVariables.forEach(({variable}) => { mapped[ variable[ 'name' ] ] = variable[ 'accountMapping' ] })
    return mapped;
  }
}
